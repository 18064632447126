.blockType {
  box-sizing: border-box;
  background: #333;
  margin: 0;
  cursor: pointer;
  line-height: 36px;
  text-align: center;
  color: #ddd;
  border-color: #ddd
}

.blockType svg {
  fill: #ddd;
}

.blockType div {
  color: #ddd;
}

.popup {
  z-index: 3;
  padding: 8px;
  visibility: hidden;
}

.popup:hover,
.popup[data-show='true'] {
  visibility: visible;
}

.popupFrame {
  border: 1px solid #111;
  background: #333;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
  z-index: 3;
  box-sizing: border-box;
  width: 74px;
}

.arrow {
  visibility: hidden;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 6px;
  height: 6px;
  background: inherit;
  border: 1px solid #111;
}

.arrow::before {
  content: '';
  transform: rotate(45deg);
}

.arrow[data-show='true']::before {
  visibility: visible;
}

.arrow[data-popper-placement^='top'] {
  bottom: 4px;
}

.arrow[data-popper-placement^='bottom'] {
  top: 4px;
}

.arrow[data-popper-placement^='left'] {
  right: 4px;
}

.arrow[data-popper-placement^='right'] {
  left: 4px;
}

.arrow[data-popper-placement^='top']::before {
  border-left: 0;
  border-top: 0;
}

.arrow[data-popper-placement^='bottom']::before {
  border-right: 0;
  border-bottom: 0;
}

.arrow[data-popper-placement^='left']::before {
  border-left: 0;
  border-bottom: 0;
}

.arrow[data-popper-placement^='right']::before {
  border-right: 0;
  border-top: 0;
}